import React from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faFilePdf,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons"

// Utility function to grab Icons by name
const Icon = ({ name, color }) => {
  switch (name.toLowerCase()) {
    case "mail":
      return <FontAwesomeIcon icon={faEnvelope} color={color} />
    case "file-pdf":
      return <FontAwesomeIcon icon={faFilePdf} color={color} />
    case "newspaper":
      return <FontAwesomeIcon icon={faNewspaper} color={color} />
    default:
      return null
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default Icon
